import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in Touch`}</h2>
    <p>{`You can find me on `}<a parentName="p" {...{
        "href": "https://www.facebook.com/ahusunxy"
      }}>{`Facebook`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.instagram.com/ahusunxyy"
      }}>{`Instagram`}</a><br parentName="p"></br>{`
`}{`You can email me @ `}<a parentName="p" {...{
        "href": "mailto:ahusunxy@mail.ru"
      }}>{`ahusunxy@mail.ru`}</a><br parentName="p"></br>{`
`}{`My LinkedIn Profile `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/ali-ahsan-saeed-60b001157/"
      }}>{`LinkedIn`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      