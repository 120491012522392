import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h3>{`Introduction`}</h3>
    <p>{`My name is Ali Ahsan Saeed, I am a computer science and data science student
who is currently doing his Bachelors Degree in Computer Science with Specialism
in Data Analytics. I am studying at Asia Pacific University and currently in my
Last Semester of my 2nd Year. `}</p>
    <h3>{`Interests`}</h3>
    <p>{`C++, Flutter, Data Structures, Algorithms, Competitive Programming
React, JavaScript, Java, Python, Machine Learning, Neural Networks
I am also keen on doing research based work. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      