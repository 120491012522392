import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Data Structures" link="https://www.github.com/nullptrz" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This project is my custom implementation of Linked List using C++ it also has 
an Iterator class which conforms to the C++ STL Standards so that it can be used
with STL algorithms. 
    </ProjectCard>
    <ProjectCard title="Concurrent Programming" link="https://www.github.com/nullptrz" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This is a text-based simulation of a program written in Java using Threads.
The concepts and techniques used are Semaphores and Mutexes for Signalling and controlling
of the Threads access to shared resources and critical sections of code. 
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      